import React, { FC } from 'react';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';
import PrivateProvider from '@/components/providers/PrivateProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';

interface CampaignNftDetailPageProps {
  params: { id: string };
}

const CampaignNftDetailLoadable = Loadable({
  loader: () => import('@/sections/Dashboard/Page/campaign-nft-detail'),
  render(loaded, props: any) {
    const Component = loaded.default;
    return <Component {...props} />;
  },
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});

const CampaignNftDetailPage: FC<CampaignNftDetailPageProps> = ({ params }) => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Campaign NFT Detail" />
      <PrivateProvider>
        <CampaignNftDetailLoadable id={params.id} />
      </PrivateProvider>
    </React.Fragment>
  );
};

export default CampaignNftDetailPage;
